import React, {useEffect } from "react";
import "../style/Home/ServicosHome.css";


          //imagens
import TelemetriaAuto from '../image/geotab-go9.jpg';
import TelemetriaSat from '../image/telemetria_satelital.png';
import Cameras from '../image/camera-para-frotas.jpg';
import Preps from '../image/preps.jpg';


export default () => {
  return(
    <div className="servicos-home">
      <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="false">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={TelemetriaAuto} class="d-block w-100" alt="..."/>
            <div class="carousel-caption d-none d-md-block">
              <h5>Telemetria Automotiva</h5>
              <p>Revolucione sua frota com o Geotab GO9. Uma das soluções telemáticas líderes do setor</p>
            </div>
          </div>
          <div class="carousel-item">
            <img src={Cameras} class="d-block w-100" alt="..."/>
            <div class="carousel-caption d-none d-md-block">
              <h5>Camera para frotas</h5>
              <p >A câmera que captura a direção e o comportamento do motorista em tempo real.</p>
            </div>
          </div>
          <div class="carousel-item">
            <img src={Preps}class="d-block w-100" alt="..."/>
            <div class="carousel-caption d-none d-md-block">
              <h5>Preps</h5>
              <p>O programa nacional de rastreamento de embarcações-Preps, visa o controle a preservação de áreas protegidas e a segurança dos barcos.</p>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  )
}